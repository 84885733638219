import React, { useContext } from "react"
import * as moment from "moment/moment"
import styled from "styled-components"
import { colors, mq } from "../../utils/styles"
import I18nContext from "../../utils/i18nContext"
import { Highlight, Snippet } from "react-instantsearch"

const Spacer = styled.div`
  margin-bottom: 1em;
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
  margin-left: calc(50px + 0.5em);

  ${mq.large} {
    flex-direction: row;
    align-items: center;
  }
`
const Content = styled.div`
  flex: 1;
  margin-right: 1em;

  a {
    text-decoration: none;
  }
`
const Title = styled.h3`
  margin: 0 0 0.4em;
  cursor: pointer;
`
const Time = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 0.5em;
  font-family: "GT Walsheim Pro Cond", serif;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.main};
  text-align: center;
  text-transform: uppercase;
  border: 2px solid ${colors.main};
  border-radius: 50%;
`
const Heure = styled.span`
  position: absolute;
  bottom: 0;
  transform: translateY(150%);
  color: ${colors.main};
`
const Venue = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;

  a {
    display: flex;
    text-decoration: none;
  }

  svg {
    margin-right: 0.5rem;
    margin-bottom: calc(5px + 0.3em);
    fill: ${colors.main};
  }
`
const VenueTitle = styled.h4`
  margin: 0;
`
export default function SearchPreview({ hit }) {
  const { lang } = useContext(I18nContext)
  moment.locale(lang)

  const venue = hit.venue
  const venueWithoutEvent = {
    ...venue,
    eventId: null,
  }

  return (
    <Spacer>
      <Wrapper>
        {hit.type === "events" && (
          <Time>
            {moment(hit.horaires.debut).format("ddd DD MMM")}
            <Heure>{moment(hit.horaires.debut).format("HH:mm")}</Heure>
          </Time>
        )}
        {hit.type !== "events" && <Time>{hit.type}</Time>}
        <Content>
          <Title onClick={() => hit.changeCurrentVenue(venue)}>
            <Highlight hit={hit} attribute="title" />
          </Title>
          <Snippet hit={hit} attribute="description" />
        </Content>
      </Wrapper>
      {hit.type === "events" && hit.lieu && (
        <Footer>
          <Venue onClick={() => hit.changeCurrentVenue(venueWithoutEvent)}>
            <svg width="13px" height="19px">
              <path
                fill="rgb(255, 255, 255)"
                d="M6.264,18.136 C6.264,18.136 0.375,10.571 0.375,6.999 C0.375,3.426 3.011,0.531 6.264,0.531 C9.516,0.531 12.153,3.426 12.153,6.999 C12.153,10.571 6.264,18.136 6.264,18.136 ZM6.420,4.419 C5.254,4.419 4.308,5.371 4.308,6.545 C4.308,7.719 5.254,8.670 6.420,8.670 C7.587,8.670 8.532,7.719 8.532,6.545 C8.532,5.371 7.587,4.419 6.420,4.419 Z"
              />
            </svg>
            <VenueTitle
              dangerouslySetInnerHTML={{
                __html: hit.lieu.title,
              }}
            />
          </Venue>
        </Footer>
      )}
    </Spacer>
  )
}
